import { createGlobalStyle } from 'styled-components';

import FormaDisplay200woff2 from 'assets/fonts/forma-djr/forma-display-200.woff2';
import FormaDisplay200woff from 'assets/fonts/forma-djr/forma-display-200.woff';
import FormaDisplay200otf from 'assets/fonts/forma-djr/forma-display-200.otf';

import FormaDisplay300woff2 from 'assets/fonts/forma-djr/forma-display-300.woff2';
import FormaDisplay300woff from 'assets/fonts/forma-djr/forma-display-300.woff';
import FormaDisplay300otf from 'assets/fonts/forma-djr/forma-display-300.otf';

import FormaDisplay500woff2 from 'assets/fonts/forma-djr/forma-display-500.woff2';
import FormaDisplay500woff from 'assets/fonts/forma-djr/forma-display-500.woff';
import FormaDisplay500otf from 'assets/fonts/forma-djr/forma-display-500.otf';

import FormaDisplay700woff2 from 'assets/fonts/forma-djr/forma-display-700.woff2';
import FormaDisplay700woff from 'assets/fonts/forma-djr/forma-display-700.woff';
import FormaDisplay700otf from 'assets/fonts/forma-djr/forma-display-700.otf';

import FormaText300woff2 from 'assets/fonts/forma-djr/forma-text-300.woff2';
import FormaText300woff from 'assets/fonts/forma-djr/forma-text-300.woff';
import FormaText300otf from 'assets/fonts/forma-djr/forma-text-300.otf';

import FormaText500woff2 from 'assets/fonts/forma-djr/forma-text-500.woff2';
import FormaText500woff from 'assets/fonts/forma-djr/forma-text-500.woff';
import FormaText500otf from 'assets/fonts/forma-djr/forma-text-500.otf';

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: display-text, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
       display: none;
       width:0;
       height: 0;
        background-color:transparent;
     }
  }
  section {
    display: inline-block;
  }
  #root {
      max-height: 100vh;
  }
  div.home-wrapper {
      max-height: calc(100vh);
      height: 100%;
      display: inline-flex;
      overflow: hidden;
  }

  @font-face {
    font-family:"forma-display";
    src:
    url(${FormaDisplay200woff2}) format("woff2"),
    url(${FormaDisplay200woff}) format("woff"),
    url(${FormaDisplay200otf}) format("opentype");
    font-display:auto;font-style:normal;font-weight:200;
  }

  @font-face {
    font-family:"forma-display";
    src:
    url(${FormaDisplay300woff2}) format("woff2"),
    url(${FormaDisplay300woff}) format("woff"),
    url(${FormaDisplay300otf}) format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
  }

  @font-face {
    font-family:"forma-display";
    src:
    url(${FormaDisplay500woff2}) format("woff2"),
    url(${FormaDisplay500woff}) format("woff"),
    url(${FormaDisplay500otf}) format("opentype");
    font-display:auto;font-style:normal;font-weight:500;
  }

  @font-face {
    font-family:"forma-display";
    src:
    url(${FormaDisplay700woff2}) format("woff2"),
    url(${FormaDisplay700woff}) format("woff"),
    url(${FormaDisplay700otf}) format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
  }

  @font-face {
    font-family:"forma-text";
    src:
    url(${FormaText300woff2}) format("woff2"),
    url(${FormaText300woff}) format("woff"),
    url(${FormaText300otf}) format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
  }

  @font-face {
    font-family:"forma-text";
    src:
    url(${FormaText500woff2}) format("woff2"),
    url(${FormaText500woff}) format("woff"),
    url(${FormaText500otf}) format("opentype");
    font-display:auto;font-style:normal;font-weight:500;
  }
`;

export const OtherPageStyle = createGlobalStyle`
  html, body {
    background: #537B72;
  }
`;
