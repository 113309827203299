import { createRef, FC, useState, cloneElement, ReactElement } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { GalleryWrapper, GalleryThumbnail, GalleryImageWrapper, GalleryImageTransitionGroupWrapper, GalleryImage, GalleryArrow } from './index.styled';

import GalleryData from './data';

import iconLeft from 'assets/images/icon-arrow-left.svg';
import iconRight from 'assets/images/icon-arrow-right.svg';

const dynamicChildFactory = (classNames: string) => (child: ReactElement): ReactElement => cloneElement(child, { classNames });

const Gallery: FC = () => {
  let { slug } = useParams<{ slug: string }>();
  const [previousSelectedImageIndex, setPreviousSelectedImageIndex] = useState(-1);
  const [transitionClass, setTransitionClass] = useState('slide-left');

  let selectedImageIndex = -1;

  if (slug) {
    selectedImageIndex = GalleryData.findIndex(image => image.slug === slug);
  }

  if (slug && selectedImageIndex === -1) {
    return (<Redirect to="/gallery"></Redirect>)
  }

  if (previousSelectedImageIndex !== selectedImageIndex) {
    setTransitionClass(previousSelectedImageIndex < selectedImageIndex ? 'slide-left' : 'slide-right');
    setPreviousSelectedImageIndex(selectedImageIndex);
  }

  return (
    <GalleryWrapper>
      {!slug && GalleryData.map((image, index) =>
        <GalleryThumbnail to={`/gallery/${image.slug}`} key={`gallery-image-${index}`}>
          <img src={image.thumbnail.src} srcSet={image.thumbnail.srcSet.join(', ')} alt={image.title} />
        </GalleryThumbnail>
      )}
      {!slug && (
        <>
          <GalleryThumbnail to={`/gallery`} key={`gallery-image-placeholder-1`} className="placeholder"></GalleryThumbnail>
          <GalleryThumbnail to={`/gallery`} key={`gallery-image-placeholder-2`} className="placeholder"></GalleryThumbnail>
        </>
      )}
      {slug && (
        <GalleryImageWrapper>
          <GalleryArrow
            className={selectedImageIndex ? '' : 'disabled'}
            to={`/gallery/${GalleryData[selectedImageIndex ? selectedImageIndex - 1 : selectedImageIndex].slug}`}
          >
            <img src={iconLeft} alt="Previous" />
          </GalleryArrow>
          <GalleryImageTransitionGroupWrapper>
            <TransitionGroup childFactory={dynamicChildFactory(transitionClass)}>
              {[0].map(() => {
                const galleryImageRef = createRef<HTMLDivElement>();
                return (
                  <CSSTransition
                    nodeRef={galleryImageRef}
                    key={`image-${GalleryData[selectedImageIndex].slug}`}
                    timeout={{ enter: 1000, exit: 1000 }}
                    classNames={transitionClass}
                  >
                    <GalleryImage ref={galleryImageRef}>
                      <img
                        src={GalleryData[selectedImageIndex].image.src}
                        srcSet={GalleryData[selectedImageIndex].image.srcSet.join(', ')}
                        alt={GalleryData[selectedImageIndex].title}
                        key={`image-${GalleryData[selectedImageIndex].slug}`}
                      />
                      {/* <div className="caption" key={`caption-${GalleryData[selectedImageIndex].slug}`}>{GalleryData[selectedImageIndex].title}</div> */}
                    </GalleryImage>
                  </CSSTransition>
                )
              })}
            </TransitionGroup>
          </GalleryImageTransitionGroupWrapper>
          <GalleryArrow
            className={selectedImageIndex < GalleryData.length - 1 ? '' : 'disabled'}
            to={`/gallery/${GalleryData[selectedImageIndex < GalleryData.length - 1 ? selectedImageIndex + 1 : selectedImageIndex].slug}`}
          >
            <img src={iconRight} alt="Next" />
          </GalleryArrow>
        </GalleryImageWrapper>
      )}
    </GalleryWrapper>
  );
};

export default Gallery;
