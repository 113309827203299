import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { mediaMax, mediaMin } from 'styles/media-queires';

export const MainHeaderContainer = styled.header`
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: calc(100% - 36px);
  padding: 18px 18px 0px 18px;
  background: #ffffff;
  &.white-header {
    background: none;
  }

  ${mediaMin.desktop`
    width: calc(100% - 64px);
    padding: 32px 32px 0px 32px;
  `}

  ${mediaMin.tabletLandscape`
    .mobile {
      display: none;
      opacity: 0;
    }
  `}

  ${mediaMax.tabletLandscape`
    .desktop {
      display: none;
      opacity: 0;
    }
  `}
`;

export const HeaderLogoBlock = styled(Link)`
  width: 286px;
  position: relative;
  cursor: pointer;
  z-index: 1;

  ${mediaMin.tabletLandscape`
    width: 120px;
  `}

  > a {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.white-header > img:nth-child(1),
  &.white-header > a:nth-child(1) > img,
  &.white-header > img:nth-child(3),
  &.white-header > a:nth-child(3) > img {
    opacity: 1;
  }
  &.white-header > img:nth-child(2),
  &.white-header > img:nth-child(4) {
    opacity: 0;
  }
`;
export const HeaderLogo = styled.img`
  width: 100%;
  opacity: 0;
  transition: 0.3s;
`;

export const HeaderLogoGreen = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0.3s;
`;

export const HeaderMainNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: .5em;
  width: 35%;

  &.white-header > div,
  &.white-header > a {
    color: #ffffff;

    a {
      color: #ffffff;
      text-decoration: none;
    }
    &:after {
      background: #ffffff;
    }
  }

  .close-button {
    display: none;
  }

  &.with-close-button > * {
    display: none;
  }

  &.with-close-button .close-button {
    display: block;
  }
`;

export const HeaderMainNavigationItem = styled(Link)`
  font: normal normal normal 1.5vh/1.5vh forma-display;
  letter-spacing: 0.9px;
  color: #9f9f9f;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  text-decoration: none;
  margin-left: auto;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    opacity: 0;
    left: 0;
    bottom: -6px;
    height: 2px;
    background: #9f9f9f;
    transition: 0.3s;
  }
  &:hover&:after {
    width: 100%;
    opacity: 1;
  }
  &.black {
    color: black !important;

    &:after {
      background: black !important;
    }
  }
  &.close-button:after {
    content: none;
  }
`;

export const HeaderMainNavigationHamburger = styled.div`
  width: 32px;
  height: 8px;
  position: relative;
  transition: .3s;
  z-index: 1;
  margin-left: auto;

  &.open-menu {
    &:after {
      bottom: 40%;
      transform: rotate(45deg);
      background: #41aa6c;
    }
    &:before {
      top: 40%;
      transform: rotate(-45deg);
      background: #41aa6c;
    }
  }

  &:after, &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #41aa6c;
    position: absolute;
    transition: .3s;
  }
  &:after {
    bottom: 0;
  }
  &:before {
    top: 0;
  }

  &.white-header:after {
    background: #ffffff;
  }
  &.white-header:before {
    background: #ffffff;
  }
`;

export const HeaderMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
`;
export const HeaderMenuNavigation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 24px 40px;
`;

export const HeaderMenuNavigationItem = styled(Link)`
  font: normal normal 500 24px/25px forma-display;
  letter-spacing: 1.8px;
  color: #41aa6c;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  margin: 0 0 24px;
  text-decoration: none;
`;

export const AtlasLogoLink = styled.a`
  position: fixed;
  bottom: 26px;
  right: 26px;
  width: 10.4vh;
`;
export const AtlasLogo = styled.img`
  width: 100%;
`;
