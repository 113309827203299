import { css } from 'styled-components';

const sizes: any = {
  phone: 375,
  phoneLarge: 414,
  phoneXL: 550,
  tablet: 768,
  tabletLandscape: 1023,
  desktopSmall: 1250,
  desktop: 1440,
  desktopLarge: 1920,
  desktopXLarge: 2048,
};

// Iterate through the sizes and create a media template
export const mediaMin = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (...args: any) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${
        // @ts-ignore
        css(...args)
      }
    }
  `;

  return acc;
}, {});

export const mediaMax = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${
        // @ts-ignore
        css(...args)
      }
    }
  `;

  return acc;
}, {});
