import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaMax, mediaMin } from 'styles/media-queires';

// Transitions
export const fadeIn = keyframes`
  0% { opacity: 0.2;}
  100% { opacity: 1;}
`;

export const enterFadeIn = () => css`
  ${fadeIn} 2s ease-out 0s forwards;
`;

export const GalleryWrapper = styled.div`
  padding-top: 80px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-start;

  ${mediaMin.desktop`
    padding-top: 160px;
  `}
`;

export const GalleryThumbnail = styled(Link)`
  width: 30%;
  margin-bottom: 40px;

  ${mediaMax.tabletLandscape`
    width: 45%;
    margin-bottom: 20px;
  `}

  ${mediaMax.tablet`
    width: 95%;
    margin-bottom: 20px;
    pointer-events: none;
  `}

  img {
    width: 100%;
    opacity: 0.2;
    animation: ${enterFadeIn};
  }

  &.placeholder {
    margin: 0;
    height: 0;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const GalleryImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content
  align-items: center;
  opacity: 0.2;
  animation: ${enterFadeIn};
`;

export const GalleryArrow = styled(Link)`
  display: flex;
  align-items: center;
  padding: 20px;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0;
  }
`

export const GalleryImageTransitionGroupWrapper = styled.div`
  flex-grow: 2;
  height: calc(100vh - 180px);
  flex-wrap: nowrap;
`

export const GalleryImage = styled.div`
  height: calc(100vh - 180px);
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .caption {
    margin-top: 10px;
    color: #9f9f9f;
    font: normal normal 300 0.75em forma-text;
  }

  &.gallery-image-enter {
    opacity: 0;
  }

  &.gallery-image-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &.gallery-image-exit {
    opacity: 1;
  }

  &.gallery-image-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  /* ANIMATIONS */
  &.slide-left-enter {
    position: absolute;
    left: 0;
    top: 140;
    right: 0;
    transform: translateX(100%);
  }
  &.slide-left-enter-active {
    transform: translateX(0%);
    transition: transform 1000ms ease-in-out;
  }
  &.slide-left-exit {
    transform: translateX(0%);
  }
  &.slide-left-exit-active {
    transform: translateX(-100%);
    transition: transform 1000ms ease-in-out;
  }

  &.slide-right-enter {
    position: absolute;
    left: 0;
    top: 140;
    right: 0;
    transform: translateX(-100%);
  }
  &.slide-right-enter-active {
    transform: translateX(0%);
    transition: transform 1000ms ease-in-out;
  }
  &.slide-right-exit {
    transform: translateX(0%);
  }
  &.slide-right-exit-active {
    transform: translateX(100%);
    transition: transform 1000ms ease-in-out;
  }
`;
