import styled from 'styled-components';

export const TourWrapper = styled.div`
  height: 100vh;
  display: inline-flex;
  overflow: hidden;
`;

export const TourRoot = styled.div`
`;
