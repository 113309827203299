import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import MainHeader from 'components/main-header';

import { GlobalStyle } from 'styles/global.styled';
import { ContextProvider } from 'providers/context-provider';
import Home from 'pages/home';
import Gallery from "pages/gallery";

function App() {
  return (
    <ContextProvider>
      <Router>
        <GlobalStyle />
        <Switch>
          <Route path="/gallery/:slug">
            <MainHeader className="grey-header with-close-button" />
            <Gallery />
          </Route>
          <Route path="/gallery">
            <MainHeader className="grey-header" />
            <Gallery />
          </Route>
          <Route path="/">
            <MainHeader className="white-header" />
            <Home />
          </Route>
        </Switch>
      </Router>
    </ContextProvider>
  );
}

export default App;
