import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useKrpano from 'react-krpano-hooks';

import { TourRoot, TourWrapper } from './index.styled';

const Home: FC = () => {
  // const [windowWidth, setWindowWidth] = useState<number>(0);

  // useEffect(() => {
  //   setWindowWidth(window.innerWidth);
  // }, []);

  // useEffect(() => {
  //   function handleResize() {
  //     setWindowWidth(window.innerWidth);
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const { containerRef, callKrpano } = useKrpano({
    scriptPath: 'krpano.js',
    embeddingParams: {
      xml: 'tour.xml',
      swf: 'tour.swf',
      target: 'react-krpano',
      html: 'prefer',
      html5: 'auto',
      mobilescale: 1.0,
      passQueryParameters: true
    },
    globalFunctions: {
      consoleLog: (...args: any[]) => {
        console.log('[Krpano logs] ', args);
      },
    }
  });

  let location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const scene = query.get('startscene');
    const actions = query.get('startactions');
    if (scene) {
      callKrpano('loadscene(' + scene + ', null, KEEPPLUGINS, BLEND(1));' + (actions || ''));
    } else {
      callKrpano('loadscene(0, null, KEEPPLUGINS, BLEND(1));lookat(0,-0.098429,0.365286,0,0);');
    }
  }, [location, callKrpano]);

  return (
    <>
      <TourWrapper>
        <TourRoot ref={containerRef} />
      </TourWrapper>
    </>
  );
};

export default Home;
