import React, { FC, useState } from 'react';
import {
  // AtlasLogo,
  // AtlasLogoLink,
  HeaderLogo,
  HeaderLogoBlock,
  HeaderLogoGreen,
  HeaderMainNavigation,
  HeaderMainNavigationHamburger,
  HeaderMainNavigationItem,
  HeaderMenu,
  HeaderMenuNavigation,
  HeaderMenuNavigationItem,
  MainHeaderContainer
} from './index.styled';

import logo from 'assets/images/logo.svg';
import logoGreen from 'assets/images/logo-green.svg';
import logoMobile from 'assets/images/logo-mobile.svg';
import logoGreenMobile from 'assets/images/logo-green-mobile.svg';
import iconClose from 'assets/images/icon-close.svg';

interface IProps {
  className?: string;
}

const MainHeader: FC<IProps> = ({ className }) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <MainHeaderContainer className={className}>
      <HeaderLogoBlock className={!openMenu ? className : ''} to="/?startscene=0&startactions=lookat(0,-0.098429,0.365286,0,0);"  onClick={() => setOpenMenu(false)}>
        <HeaderLogo src={logo} alt="101 South Marengo Logo" className="desktop" />
        <HeaderLogoGreen src={logoGreen} alt="101 South Marengo Logo" className="desktop" />
        <HeaderLogo src={logoMobile} alt="101 South Marengo Logo" className="mobile" />
        <HeaderLogoGreen src={logoGreenMobile} alt="101 South Marengo Logo" className="mobile" />
      </HeaderLogoBlock>
      <HeaderMainNavigation className={!openMenu ? className : ''}>
        <HeaderMainNavigationItem to="/?startscene=1&startactions=lookat(0,0,110,0,0);" className="desktop">
          Tour The Building
        </HeaderMainNavigationItem>
        <HeaderMainNavigationItem to="/?startscene=6&startactions=lookat(-90,0,110,0,0);" className="desktop">
          Tour Interiors
        </HeaderMainNavigationItem>
        <HeaderMainNavigationItem to="/gallery" className="desktop">
          Image Gallery
        </HeaderMainNavigationItem>
        <HeaderMainNavigationItem to="/gallery" className="close-button">
          <img src={iconClose} alt="Close" />
        </HeaderMainNavigationItem>
        <HeaderMainNavigationHamburger
          className={`mobile ${openMenu ? 'open-menu' : ''} ${!openMenu ? className : ''}`}
          onClick={() => setOpenMenu(openStatus => !openStatus)}
        />
      </HeaderMainNavigation>
      <HeaderMenu style={{ height: openMenu ? '100vh' : 0 }} className="mobile">
        <HeaderMenuNavigation>
          <HeaderMenuNavigationItem to="/?startscene=1&startactions=lookat(0,0,110,0,0);" onClick={() => setOpenMenu(false)}>
            Tour The Building
          </HeaderMenuNavigationItem>
          <HeaderMenuNavigationItem to="/?startscene=6&startactions=lookat(-90,0,110,0,0);" onClick={() => setOpenMenu(false)}>
            Tour Interiors
          </HeaderMenuNavigationItem>
          <HeaderMenuNavigationItem to="/gallery" onClick={() => setOpenMenu(false)}>
            Image Gallery
          </HeaderMenuNavigationItem>
        </HeaderMenuNavigation>
      </HeaderMenu>
      {/* <AtlasLogoLink href="https://www.atlas-cap.com/" target="_blank">
        <AtlasLogo src={atlasLogo} alt="atlas logo" />
      </AtlasLogoLink> */}
    </MainHeaderContainer>
  );
};

export default MainHeader;
