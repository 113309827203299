const data = [
  {
    image: {
      src: require('assets/images/gallery/03-raised-hero.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/03-raised-hero.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/03-raised-hero.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/03-raised-hero.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/03-raised-hero.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/03-raised-hero.jpg').default} 800w`,
        `${require('assets/images/gallery/400/03-raised-hero.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/03-raised-hero.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/03-raised-hero.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/03-raised-hero.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/03-raised-hero.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/03-raised-hero.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/03-raised-hero.jpg').default} 800w`,
        `${require('assets/images/gallery/400/03-raised-hero.jpg').default} 400w`,
      ]
    },
    title: 'Raised hero',
    slug: 'raised-hero'
  },
  {
    image: {
      src: require('assets/images/gallery/01-overall.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/01-overall.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/01-overall.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/01-overall.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/01-overall.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/01-overall.jpg').default} 800w`,
        `${require('assets/images/gallery/400/01-overall.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/01-overall-cropped.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/01-overall-cropped.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/01-overall-cropped.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/01-overall-cropped.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/01-overall-cropped.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/01-overall-cropped.jpg').default} 800w`,
        `${require('assets/images/gallery/400/01-overall-cropped.jpg').default} 400w`,
      ]
    },
    title: 'Overall',
    slug: 'overall'
  },
  {
    image: {
      src: require('assets/images/gallery/02-ne-entry.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/02-ne-entry.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/02-ne-entry.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/02-ne-entry.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/02-ne-entry.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/02-ne-entry.jpg').default} 800w`,
        `${require('assets/images/gallery/400/02-ne-entry.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/02-ne-entry.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/02-ne-entry.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/02-ne-entry.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/02-ne-entry.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/02-ne-entry.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/02-ne-entry.jpg').default} 800w`,
        `${require('assets/images/gallery/400/02-ne-entry.jpg').default} 400w`,
      ]
    },
    title: 'NE entry',
    slug: 'ne-entry'
  },
  {
    image: {
      src: require('assets/images/gallery/04-escalator-lobby.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/04-escalator-lobby.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/04-escalator-lobby.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/04-escalator-lobby.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/04-escalator-lobby.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/04-escalator-lobby.jpg').default} 800w`,
        `${require('assets/images/gallery/400/04-escalator-lobby.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/04-escalator-lobby.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/04-escalator-lobby.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/04-escalator-lobby.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/04-escalator-lobby.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/04-escalator-lobby.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/04-escalator-lobby.jpg').default} 800w`,
        `${require('assets/images/gallery/400/04-escalator-lobby.jpg').default} 400w`,
      ]
    },
    title: 'Escalator lobby',
    slug: 'escalator-lobby'
  },
  {
    image: {
      src: require('assets/images/gallery/05-atrium-garden.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/05-atrium-garden.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/05-atrium-garden.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/05-atrium-garden.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/05-atrium-garden.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/05-atrium-garden.jpg').default} 800w`,
        `${require('assets/images/gallery/400/05-atrium-garden.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/05-atrium-garden-cropped.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/05-atrium-garden-cropped.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/05-atrium-garden-cropped.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/05-atrium-garden-cropped.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/05-atrium-garden-cropped.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/05-atrium-garden-cropped.jpg').default} 800w`,
        `${require('assets/images/gallery/400/05-atrium-garden-cropped.jpg').default} 400w`,
      ]
    },
    title: 'Atrium garden',
    slug: 'atrium-garden'
  },
  {
    image: {
      src: require('assets/images/gallery/06-office-atrium.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/06-office-atrium.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/06-office-atrium.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/06-office-atrium.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/06-office-atrium.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/06-office-atrium.jpg').default} 800w`,
        `${require('assets/images/gallery/400/06-office-atrium.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/06-office-atrium.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/06-office-atrium.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/06-office-atrium.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/06-office-atrium.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/06-office-atrium.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/06-office-atrium.jpg').default} 800w`,
        `${require('assets/images/gallery/400/06-office-atrium.jpg').default} 400w`,
      ]
    },
    title: 'Office atrium',
    slug: 'office-atrium'
  },
  {
    image: {
      src: require('assets/images/gallery/07-office.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/07-office.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/07-office.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/07-office.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/07-office.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/07-office.jpg').default} 800w`,
        `${require('assets/images/gallery/400/07-office.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/07-office.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/07-office.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/07-office.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/07-office.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/07-office.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/07-office.jpg').default} 800w`,
        `${require('assets/images/gallery/400/07-office.jpg').default} 400w`,
      ]
    },
    title: 'Office',
    slug: 'office'
  },
  {
    image: {
      src: require('assets/images/gallery/08-office-corner.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/08-office-corner.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/08-office-corner.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/08-office-corner.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/08-office-corner.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/08-office-corner.jpg').default} 800w`,
        `${require('assets/images/gallery/400/08-office-corner.jpg').default} 400w`,
      ]
    },
    thumbnail: {
      src: require('assets/images/gallery/08-office-corner.jpg').default,
      srcSet: [
        `${require('assets/images/gallery/2500/08-office-corner.jpg').default} 2500w`,
        `${require('assets/images/gallery/2000/08-office-corner.jpg').default} 2000w`,
        `${require('assets/images/gallery/1500/08-office-corner.jpg').default} 1500w`,
        `${require('assets/images/gallery/1100/08-office-corner.jpg').default} 1100w`,
        `${require('assets/images/gallery/800/08-office-corner.jpg').default} 800w`,
        `${require('assets/images/gallery/400/08-office-corner.jpg').default} 400w`,
      ]
    },
    title: 'Office corner',
    slug: 'office-corner'
  },
];

export default data;
